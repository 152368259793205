import { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Link } from "react-router-dom"
import { Pagination } from "../../component/Pagination"
import { Courses, host } from "../../classes"
import { Loader } from "../../component/Loader"
import close from '../../images/closeblack.svg'

import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { Suitable } from "../../component/Suitable"
import axios from "axios"

export const CoursesPage = () => {
    const auth = useContext(GlobalContext)
    const [openPopup, setOpenPopup] = useState(false)
    const [load, setLoad] = useState(true)
    const [search, setSearch] = useState("")
    const [courses, setCourses] = useState(new Courses)
    const [teachers, setTeachers] = useState([])
    
    const aboutRef = useRef<TinyMCEEditor>();
    const contentRef = useRef<TinyMCEEditor>();
    //console.log(editorRef.current.getContent());

    const [suitable, setSuitable] = useState([
        {
            "pk": 0,
            "image": "",
            "title": "",
            "miniDesc": ""
        }
    ])

    const [form, setForm] = useState({
        "title": "",
        "miniDesc": "", 
        "duration": "",
        "type": "",
        "about": "",
        "content": "",

        "learn1": "",
        "learn2": "",
        "learn3": "",
        "learn4": "",
        "learn5": "",
        "learn6": "",

        "suitable": [{}],
        "teachers": [],
        "descPercent": 0,
        "discountDesc": "",
        "certificate_image": "",
        "certificate_title": "",
        "certificate_description": "",
        "formLink": ""
    })
 

    
    const GetTeachers = async () => {
        await axios.get(`${host}teachers-list/`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${auth.user.userToken}`
            }
        }).then((res) => { 
            setTeachers(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }

    const GetData = (page: number) => {
        setLoad(true) 
        courses.getData(auth.user.userToken, page, search).then(function(response){
            GetTeachers().then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    }


    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [event.target.name]: event.target.value})
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        const array = {
            ...form,
            formLink: form.formLink != "" ? form.formLink : "-",
            about: aboutRef.current!.getContent(),
            content: contentRef.current!.getContent(),
            suitable: suitable
        } 
  

        courses.setData(auth.user.userToken, array).then(function(responce){
            setForm({
                "title": "",
                "miniDesc": "", 
                "duration": "",
                "type": "",
                "about": "",
                "content": "",
                "learn1": "",
                "learn2": "",
                "learn3": "",
                "learn4": "",
                "learn5": "",
                "learn6": "",
                "suitable": [{}],
                "teachers": [],
                "descPercent": 0,
                "discountDesc": "",
                "certificate_image": "",
                "certificate_title": "",
                "certificate_description": "",
                "formLink": ""
            })
            alert(responce)
            setOpenPopup(false)
            GetData(1)
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{
        GetData(1) 
    },[])
    
    if(load){
        return(
            <Loader />
        )
    }
    
    return(
        <>
            <div className="content">
                <div className="user-add">
                    <div className="row">
                        <div className="col-md-6">
                            <form onSubmit={(event: React.FormEvent<HTMLFormElement>)=>{
                                event.preventDefault()
                                GetData(1)
                            }}>
                                <input type="text" value={search} onChange={(e)=>{
                                    setSearch(e.target.value)
                                }} placeholder="Поиск" />
                                <button>Найти</button>
                            </form>
                        </div>
                        <div className="col-md-6 right">
                            <button onClick={()=>{setOpenPopup(true)}}>Добавить курс</button>
                        </div>
                    </div>
                </div>
                <div className='table-list'>
                    <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Название курса</th>
                                    <th>Описание</th>
                                    <th>Цена</th>
                                    <th>Активность</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    courses.list.length ? (
                                        courses.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td aria-label="ID">
                                                        <Link to={`/courses/${item.pk}/`}>
                                                            {item.pk}
                                                        </Link>
                                                    </td> 
                                                    <td aria-label="Название курса">{item.title}</td>
                                                    <td aria-label="Описание">{item.miniDesc}</td>
                                                    <td aria-label="Цена">
                                                        {
                                                            item.descPercent != 0 && (<span style={{fontSize: 14, color: "red"}}>-{item.descPercent}%<br/></span>)
                                                        }
                                                        {item.cost - (item.cost / 100 * item.descPercent)} руб
                                                    </td>
                                                    <td aria-label="Активность">
                                                        <button onClick={()=>{
                                                            item.UpdateActive(auth.user.userToken).then(function(response){
                                                                alert(response)
                                                                GetData(1)
                                                            }).catch(function(error){
                                                                alert("error")
                                                            })
                                                        }} style={{backgroundColor: !item.active ? "red":"green"}}>
                                                            {item.active ? "Да":"Нет"}
                                                        </button>
                                                    </td>
                                                </tr> 
                                            )
                                        })
                                    ):""
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-list__pager">    
                    <Pagination 
                        page={courses.page}
                        pageCount={courses.pageCount}
                        setLoadReview={setLoad} 
                        SwitchPage={GetData}                         
                    />      
                </div>
            </div>

            {
                openPopup ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        Название курса
                                        <input maxLength={255} required name="title" value={form.title} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Краткое описание
                                        <textarea maxLength={510} required name="miniDesc" value={form.miniDesc} onChange={(event: ChangeEvent<HTMLTextAreaElement>)=>{    
                                            setForm({...form, [event.target.name]: event.target.value})
                                        }} />
                                    </label> 
                                    <label>
                                        Длительность курса
                                        <input type="text" maxLength={255} required name="duration" value={form.duration} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Тип курса
                                        <input type="text" required name="type" value={form.type} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Ссылка на форму для формирования диплома<br/>
                                        <span style={{fontSize: 14}}>(Оставьте пустым если курс выпускает только сертификата)</span>
                                        <input type="text" name="formLink" value={form.formLink} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Ссылка на фото сертификата
                                        <input type="text" required name="certificate_image" value={form.certificate_image} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Заголовок блока сертификата
                                        <input type="text" maxLength={255} required name="certificate_title" value={form.certificate_title} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Описание блока сертификата
                                        <input type="text" maxLength={255} required name="certificate_description" value={form.certificate_description} onChange={changeHandler} />
                                    </label>

                                    <label>
                                        Процент скидки на покупку курса целиком
                                        <input type="number" step="1" min="0" max="99" maxLength={255} required name="descPercent" value={form.descPercent} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Текст в блоке цены
                                        <textarea maxLength={510} value={form.discountDesc} name="discountDesc" required onChange={(event: ChangeEvent<HTMLTextAreaElement>)=>{    
                                            setForm({...form, [event.target.name]: event.target.value})
                                        }} />
                                    </label>
                                    <label>
                                        О чём курс
                                        <Editor
                                            onInit={(evt, editor) => aboutRef.current = editor}
                                            apiKey='te0f6xps8s75ppw6vsh5netbpg8ber5hvml5ad5balibyuj7' 
                                            initialValue="<p>Описание</p>"
                                            init={{
                                                height: 300,
                                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'],
                                                toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </label>
                                    <label>
                                        Описание курса (страница после покупки)
                                        <Editor
                                        onInit={(evt, editor) => contentRef.current = editor}
                                            apiKey='te0f6xps8s75ppw6vsh5netbpg8ber5hvml5ad5balibyuj7'  
                                            initialValue="<p>Описание курса</p>"
                                            init={{
                                                height: 300,
                                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'],
                                                toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </label>

                                    <label>
                                        Чему вы научитесь
                                        <div className="course-learn">
                                            <input type="text" placeholder="1." required name="learn1" value={form.learn1} onChange={changeHandler} />
                                            <input type="text" placeholder="2." required name="learn2" value={form.learn2} onChange={changeHandler} />
                                            <input type="text" placeholder="3." required name="learn3" value={form.learn3} onChange={changeHandler} />
                                            <input type="text" placeholder="4." name="learn4" value={form.learn4} onChange={changeHandler} />
                                            <input type="text" placeholder="5." name="learn5" value={form.learn5} onChange={changeHandler} />
                                            <input type="text" placeholder="6." name="learn6" value={form.learn6} onChange={changeHandler} />
                                        </div>
                                    </label>

                                    <label>
                                        Кому подойдет курс
                                        {
                                            suitable.map((item, index) => {
                                                return(
                                                    <Suitable item={item} suitable={suitable} setSuitable={setSuitable} index={index} />
                                                )
                                            })
                                        }
                                    </label>

                                    <label className="prepods-list">
                                        Преподаватели <span>(зажмите shift для выбора нескольких преподавателей)</span>
                                        <select 
                                            multiple 
                                            name="teachers"
                                            value={form.teachers}
                                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                                const options = event.target.options
                                                let value = []
                                                
                                                for(let i = 0, l = options.length; i < l; i++) {
                                                    if (options[i].selected) {
                                                        value.push(options[i].value)
                                                    }
                                                }

                                                setForm({...form, [event.target.name]: value})
                                            }}
                                        >
                                            {
                                                teachers.length ? (
                                                    teachers.map((item: any, index: number) => {
                                                        return (
                                                            <option value={item.pk}>{item.title}</option>
                                                        )
                                                    })
                                                ):""
                                            }
                                        </select>
                                    </label>


                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}