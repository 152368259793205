import { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { Link, useParams } from "react-router-dom"
import { CourseItem, host } from "../../../classes"
import { Loader } from "../../../component/Loader"
import close from '../../../images/closeblack.svg'
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import axios from "axios"
import xlsx from "json-as-xlsx"
import { Suitable } from "../../../component/Suitable"

export const CoursesItemPage = () => {
    const auth = useContext(GlobalContext) 
    const { pk } = useParams()
    const aboutRef = useRef<TinyMCEEditor>();
    const contentRef = useRef<TinyMCEEditor>();
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupCertificates, setOpenPopupCertificates] = useState(false)
    
    const [openPopupDImage, setOpenPopupDImage] = useState(false)
    const [openPopupSetting, setOpenPopupSetting] = useState(false)
    const [openPopupModule, setOpenPopupModule] = useState(false)
    const [teachers, setTeachers] = useState([])
    
    const [load, setLoad] = useState(true)

    const [image, setImage] = useState()
    const [imageDPage, setImageDPage] = useState()
    const [imageCertificates, setImageCertificates] = useState()

    const [course, setCourse] = useState(new CourseItem)
    
    const [form, setForm] = useState({
        "title": "",
        "miniDesc": "", 
        "duration": "",
        "type": "",
        "about": "",
        "content": "",

        "learn1": "",
        "learn2": "",
        "learn3": "",
        "learn4": "",
        "learn5": "",
        "learn6": "",

        "suitable": [{
            "pk": 0,
            "image": "",
            "title": "",
            "miniDesc": ""
        }],
        "teachers": [],
        "descPercent": 0,
        "discountDesc": "",
        "certificate_image": "",
        "certificate_title": "",
        "certificate_description": "",
        "formLink": ""
    })

    const [formModule, setFormModule] = useState({
        title: "",
        miniDesc: "",
        cost: 0,
        sort: 0
    })

    const [suitable, setSuitable] = useState([
        {
            "pk": 0,
            "image": "",
            "title": "",
            "miniDesc": ""
        }
    ])

    const GetTeachers = async () => {
        await axios.get(`${host}teachers-list/`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Token ${auth.user.userToken}`
            }
        }).then((res) => { 
            setTeachers(res.data)
        }).catch(function(error){
            console.log(error)
        })
    }

    const GetData = () => {
        setLoad(true)
        course.GetData(auth.user.userToken).then(function(response){
            setForm({
                "title": response.title,
                "miniDesc": response.miniDesc, 
                "duration": response.duration,
                "type": response.type,
                "about": response.about,
                "content": response.content,
    
                "learn1": response.learn1,
                "learn2": response.learn2,
                "learn3": response.learn3,
                "learn4": response.learn4,
                "learn5": response.learn5,
                "learn6": response.learn6,
        
                "suitable": response.suitable_course,
                "teachers": response.teachers,
                "descPercent": response.descPercent,
                "discountDesc": response.discountDesc,

                "certificate_image": response.certificate_image,
                "certificate_title": response.certificate_title,
                "certificate_description": response.certificate_description,

                "formLink": response.formLink
            })
            setSuitable(response.suitable_course)
            GetTeachers().then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
        }).catch(function(error){
            alert("error")
        })
    }

    const UpdateStatus = () => {
        setLoad(true)
        course.UpdatePreview(auth.user.userToken).then(function(responce){
            alert("Статус предпросмотра обновлен")
            window.location.reload()
        }).catch(function(error){
            alert("error")
        })
    }

    const changeHandlerModule = (event: ChangeEvent<HTMLInputElement>) => {
        setFormModule({...formModule, [event.target.name]: event.target.value})
    }

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [event.target.name]: event.target.value})
    }

    const handleSubmitDPage = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault() 
        course.SetImage(auth.user.userToken, imageDPage, true).then(function(response){
            alert(response)
            setOpenPopup(false)
        }).catch(function(error){
            alert("error")
        })
    }

    const handleSubmitImage = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault() 
        course.SetImage(auth.user.userToken, image).then(function(response){
            alert(response)
            setOpenPopup(false)
        }).catch(function(error){
            alert("error")
        })
    }

    const handleSubmitImageCertificates = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault() 
        course.SetCertificates(auth.user.userToken, imageCertificates).then(function(response){
            alert(response)
            setOpenPopup(false)
        }).catch(function(error){
            alert("error")
        })
    }

    const handleSubmitModule = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault() 
        setLoad(true)
        course.SetModule(auth.user.userToken, formModule).then(function(response){
            alert(response)
            GetData()
            setOpenPopupModule(false)
            
        }).catch(function(error){
            alert("error")
        })
    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        const array = {
            ...form,
            formLink: form.formLink != "" ? form.formLink : "-",
            about: aboutRef.current!.getContent(),
            content: contentRef.current!.getContent(),
            suitable: suitable
        } 

        course.UpdateData(auth.user.userToken, array).then(function(responce){
            setForm({
                "title": "",
                "miniDesc": "", 
                "duration": "",
                "type": "",
                "about": "",
                "content": "",
                "learn1": "",
                "learn2": "",
                "learn3": "",
                "learn4": "",
                "learn5": "",
                "learn6": "",
                "suitable": [{
                    "pk": 0,
                    "image": "",
                    "title": "",
                    "miniDesc": ""
                }],
                "teachers": [],
                "descPercent": 0,
                "discountDesc": "",
                
                "certificate_image": "",
                "certificate_title": "",
                "certificate_description": "",
                "formLink": ""
            })
            alert(responce)
            setOpenPopupSetting(false)
            GetData()
        }).catch(function(error){
            alert("error")
        })
    }

    const getCourseStats = async () => {
        let data: {
            modules: {
                id: number,
                title: string,
                user: number
            }[],
            users: number,
            complite: number
        } = {
            modules: [{
                id: 0,
                title: "",
                user: 56
            }],
            users: 0,
            complite: 0
        }

        await axios.post(`${host}statistics/`,{
            type: 3,
            date_start: "2020-12-12",
            date_end: "2020-12-12"
        }, {
            params: {
                'course': pk
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.user.userToken}`
            }
        }).then(function(responce){
            data = responce.data.data
        })
        
        return data
    }

    const getStatistics = () => {
        setLoad(true)
        getCourseStats().then(function(responce){
            setLoad(false)

            let data = [
                {
                    sheet: "Модули",
                    columns: [
                        { label: "ID", value: "id" },
                        { label: "Название", value: "title" },
                        { label: "Количество участников", value: "users" },
                    ],
                    content: responce.modules
                }, 
                {
                    sheet: "Статистика",
                    columns: [
                        { label: "Количество участников всего", value: "users" }, 
                        { label: "Количество прошедших курс полностью", value: "complite" }, 
                    ],
                    content: [{
                        users: responce.users,
                        complite: responce.complite
                    }]
                },
            ]
            let settings = {
                fileName: "Statistics",
            }
            xlsx(data, settings)
        }).catch(function(error){
            alert("error")
        })
    }

    useEffect(()=>{
        if(pk)
            course.pk = Number(pk)
        GetData()
    },[])
    
    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className="content">
                <div className="courses-desc">
                    <div className="courses-desc__btn">
                        <Link to={`/courses/`}>
                            {`< Назад`}
                        </Link>
                        <button onClick={()=>{setOpenPopup(true)}} style={{marginRight: 10}}>Изменить фото курса</button>
                        <button onClick={()=>{setOpenPopupCertificates(true)}} style={{marginRight: 10}}>Фото сертификата</button>
                        <button onClick={()=>{setOpenPopupDImage(true)}} style={{marginRight: 10}}>Изменить детальное фото</button>
                        <button onClick={()=>{setOpenPopupSetting(true)}} style={{marginRight: 10}}>Изменить курс</button>
                        <button onClick={()=>{getStatistics()}} style={{marginRight: 10, backgroundColor: "green"}}>Статистика курса</button>
                        <button onClick={()=>{setOpenPopupModule(true)}} style={{marginRight: 10, backgroundColor: "green"}}>Создать модуль</button>
                        <button 
                            onClick={()=>{UpdateStatus()}}
                            style={{
                                backgroundColor: `${course.previewActive ? "red" : "green"}`
                            }}
                        >
                            {
                                course.previewActive ? "Отключить предпросмотр" : "Включить предпросмотр"
                            }
                            
                        </button>
                    </div>
                    <h1>
                        Курс: {course.title}
                        {
                            course.previewActive ? ( 
                                <Link 
                                    to={`https://kurs.als-info.ru/courses/${course.previewUID}/`} 
                                    target="_blank"
                                    style={{
                                        marginLeft: 15,
                                        fontSize: 16,
                                        textDecoration: 'none',
                                        color: 'rgba(242, 100, 33, 1)'
                                    }}
                                >
                                    Предварительный просмотр
                                </Link> 
                            ):""
                        }
                    </h1>
                   
                    <p>{course.miniDesc}</p>
                </div>
                <div className="courses-module">

                <div className='table-list__body'>
                        <table className="delivery">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Сортировка</th>
                                    <th>Название модуля</th>
                                    <th>Описание</th>
                                    <th>Цена</th>
                                    <th>Активность</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    course.list.length ? (
                                        course.list.map((item, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td aria-label="ID">
                                                        <Link to={`/courses/${pk}/${item.pk}/`}>
                                                            {item.pk}
                                                        </Link>
                                                    </td>
                                                    <td aria-label="Сортировка">{item.sort}</td>
                                                    <td aria-label="Название модуля">{item.title}</td>
                                                    <td aria-label="Описание">{item.miniDesc}</td>
                                                    <td aria-label="Цена"> 
                                                        {item.cost} руб
                                                    </td>
                                                    <td aria-label="Активность">
                                                    <button onClick={()=>{
                                                            item.UpdateActive(auth.user.userToken).then(function(response){
                                                                alert(response)
                                                                GetData()
                                                            }).catch(function(error){
                                                                alert("error")
                                                            })
                                                        }} style={{backgroundColor: !item.active ? "red":"green"}}>
                                                            {item.active ? "Да":"Нет"}
                                                        </button>
                                                    </td>
                                                </tr> 
                                            )
                                        })
                                    ):""
                                }
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>

            {
                openPopup ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopup(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmitImage}>
                                    <label>
                                        Фото курса
                                        <input type="file" maxLength={255} required name="image" accept=".jpg,.jpeg,.png" onChange={(e: any)=>{setImage(e.target.files[0])}} />
                                    </label>
                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }

            {
                openPopupCertificates ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupCertificates(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmitImageCertificates}>
                                    <label>
                                        Фото сертификата (2400x1697px) PNG
                                        <input type="file" maxLength={255} required name="image" accept=".jpg,.jpeg,.png" onChange={(e: any)=>{setImageCertificates(e.target.files[0])}} />
                                    </label>
                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }


            {
                openPopupDImage ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupDImage(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmitDPage}>
                                    <label>
                                        Фото курса (Детальное) (Размер 525x577px)
                                        <input type="file" maxLength={255} required name="imageDPage" accept=".jpg,.jpeg,.png" onChange={(e: any)=>{setImageDPage(e.target.files[0])}} />
                                    </label>
                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }

            {
                openPopupModule ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupModule(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmitModule}>
                                    <label>
                                        Сортировка
                                        <input type="number" min={0} required name="sort" value={formModule.sort} onChange={changeHandlerModule} />
                                    </label>
                                    <label>
                                        Название модуля
                                        <input type="text" maxLength={255} required name="title" value={formModule.title} onChange={changeHandlerModule} />
                                    </label>
                                    <label>
                                        Описание модуля
                                        <textarea maxLength={510} required name="miniDesc" value={formModule.miniDesc} onChange={(event: ChangeEvent<HTMLTextAreaElement>)=>{    
                                            setFormModule({...formModule, [event.target.name]: event.target.value})
                                        }} />
                                    </label>
                                    <label>
                                        Цена модуля
                                        <input type="number" min={0} required name="cost" value={formModule.cost} onChange={changeHandlerModule} />
                                    </label> 
                                    <button>Создать</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }

            {
                openPopupSetting ? (
                    <div className="popup-form">
                        <div>
                            <div className="popup-close">
                                <img src={close} onClick={()=>{setOpenPopupSetting(false)}} />
                            </div>
                            <div className="adduser-form"> 
                                <form onSubmit={handleSubmit}> 
                                    <label>
                                        Название курса
                                        <input maxLength={255} required name="title" value={form.title} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Краткое описание
                                        <textarea maxLength={510} required name="miniDesc" value={form.miniDesc} onChange={(event: ChangeEvent<HTMLTextAreaElement>)=>{    
                                            setForm({...form, [event.target.name]: event.target.value})
                                        }} />
                                    </label> 
                                    <label>
                                        Длительность курса
                                        <input type="text" maxLength={255} required name="duration" value={form.duration} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Тип курса
                                        <input type="text" maxLength={255} required name="type" value={form.type} onChange={changeHandler} />
                                    </label>

                                    <label>
                                        Ссылка на форму для формирования диплома<br/>
                                        <span style={{fontSize: 14}}>(Оставьте пустым если курс выпускает только сертификата)</span>
                                        <input type="text" name="formLink" value={form.formLink} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Ссылка на фото сертификата
                                        <input type="text" required name="certificate_image" value={form.certificate_image} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Заголовок блока сертификата
                                        <input type="text" maxLength={255} required name="certificate_title" value={form.certificate_title} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Описание блока сертификата
                                        <input type="text" maxLength={255} required name="certificate_description" value={form.certificate_description} onChange={changeHandler} />
                                    </label>

                                    <label>
                                        Процент скидки на покупку курса целиком
                                        <input type="number" step="1" min="0" max="99" maxLength={255} required name="descPercent" value={form.descPercent} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        Текст в блоке цены
                                        <textarea maxLength={510} value={form.discountDesc} name="discountDesc" required onChange={(event: ChangeEvent<HTMLTextAreaElement>)=>{    
                                            setForm({...form, [event.target.name]: event.target.value})
                                        }} />
                                    </label>
                                    <label>
                                        О чём курс
                                        <Editor
                                            onInit={(evt, editor) => aboutRef.current = editor}
                                            apiKey='te0f6xps8s75ppw6vsh5netbpg8ber5hvml5ad5balibyuj7' 
                                            initialValue={form.about}
                                            init={{
                                                height: 300,
                                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'],
                                                toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </label>
                                    <label>
                                        Описание курса (страница после покупки)
                                        <Editor
                                        onInit={(evt, editor) => contentRef.current = editor}
                                            apiKey='te0f6xps8s75ppw6vsh5netbpg8ber5hvml5ad5balibyuj7'  
                                            initialValue={form.content}
                                            init={{
                                                height: 300,
                                                plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'],
                                                toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </label>

                                    <label>
                                        Чему вы научитесь
                                        <div className="course-learn">
                                            <input type="text" placeholder="1." required name="learn1" value={form.learn1} onChange={changeHandler} />
                                            <input type="text" placeholder="2." required name="learn2" value={form.learn2} onChange={changeHandler} />
                                            <input type="text" placeholder="3." required name="learn3" value={form.learn3} onChange={changeHandler} />
                                            <input type="text" placeholder="4." name="learn4" value={form.learn4} onChange={changeHandler} />
                                            <input type="text" placeholder="5." name="learn5" value={form.learn5} onChange={changeHandler} />
                                            <input type="text" placeholder="6." name="learn6" value={form.learn6} onChange={changeHandler} />
                                        </div>
                                    </label>

                                    <label>
                                        Кому подойдет курс
                                        {
                                            suitable.length ? (
                                                suitable.map((item, index) => {
                                                    return(
                                                        <Suitable item={item} suitable={suitable} setSuitable={setSuitable} index={index} />
                                                    )
                                                })
                                            ):""
                                        }
                                    </label>

                                    <label className="prepods-list">
                                        Преподаватели <span>(зажмите shift для выбора нескольких преподавателей)</span>
                                        <select 
                                            multiple 
                                            name="teachers"
                                            value={form.teachers}
                                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                                const options = event.target.options
                                                let value = []
                                                
                                                for(let i = 0, l = options.length; i < l; i++) {
                                                    if (options[i].selected) {
                                                        value.push(options[i].value)
                                                    }
                                                }

                                                setForm({...form, [event.target.name]: value})
                                                console.log(value)
                                            }}
                                        >
                                            {
                                                teachers.length ? (
                                                    teachers.map((item: any, index: number) => {
                                                        return (
                                                            <option value={item.pk}>{item.title}</option>
                                                        )
                                                    })
                                                ):""
                                            }
                                        </select>
                                    </label>


                                    <button>Обновить</button>
                                </form>
                            </div>
                        </div>
                    </div>
                ):""
            }
        </>
    )
}